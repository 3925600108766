import Footer from "../components/Footer";
import Header from "../components/Header";

const signList = [
  {
    icon: "/images/sign/google.svg",
    text: "Continue with Google",
    link: "/#",
  },
  {
    icon: "/images/sign/facebook.svg",
    text: "Continue with Facebook",
    link: "/#",
  },
];

const Line = () => {
  return (
    <svg
      width="202"
      height="2"
      viewBox="0 0 202 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1H201" stroke="#E8EAF2" stroke-linecap="round" />
    </svg>
  );
};

export default function SignIn() {
  return (
    <>
      <Header />
      <main className="pt-16 min-h-[calc(100vh-137px)] lg:pt-14 lg:min-h-full relative z-[2]">
        <section className="py-20 lg:px-4">
          <div className="max-w-[520px] mx-auto w-full shadow-card text-center rounded-xl px-8 py-10">
            <h2 className="text-2xl font-extrabold">Sign in</h2>
            <p className="text-sm mt-2">Sign in to your account to continue.</p>
            <ul className="mt-8 space-y-6">
              {signList.map((v, i) => {
                return (
                  <li className="border py-3" key={i}>
                    <a
                      className="flex items-center justify-center gap-x-2"
                      href={v.link}
                    >
                      <img src={v.icon} alt={v.text} />
                      <span className=" font-semibold">{v.text}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <p className="text-sm mt-5 flex items-center gap-x-0">
              <Line />
              <span className="px-5">or</span>
              <Line />
            </p>
            <form className="mt-5 space-y-5 relative">
              <input
                className="bg-[#F6F7F8] p-3 text-base font-semibold text-[#999999] block w-full"
                placeholder="Email"
              />
              <input
                className="bg-[#F6F7F8] p-3  text-base font-semibold text-[#999999] block w-full relative"
                placeholder="Password"
              />
              <img
                src="/images/sign/eye.svg"
                alt="Password"
                className=" absolute bottom-0 right-0 -translate-y-1/2 -translate-x-4"
              />
            </form>
            <div className="flex justify-between w-full text-[#999999] mt-5 text-sm">
              <div className="flex items-center space-x-2">
                <input type="checkbox"></input>
                <p>Keep me login</p>
              </div>
              <p>Forgot password?</p>
            </div>
            <a
              href="/#"
              className="w-full flex text-white justify-center mt-11 rounded bg-blue-light py-3 hover:bg-[#0F59D3] hover:shadow-card2"
            >
              Login
            </a>
            <p className="mt-8 text-sm">
              Don't have an account?{" "}
              <a href="/sign-up" className="text-blue-light hover:underline">
                Start here
              </a>
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
