import React from "react";
import Router from "./router/index";

function App() {
  return (
    <>
      <Router></Router>
    </>
  );
}

export default App;
