import Footer from "../components/Footer";
import Header from "../components/Header";
import RightNav from "../components/RightNav";

export default function Cookie() {
  return (
    <>
      <Header />
        <div className="max-w-[1124px] px-4 pt-[144px] pb-16 mx-auto flex items-start lg:flex-col lg:pt-14 lg:pb-16">
          <article>
          <h1 className="font-bold">Cookie Notice</h1>
            <p>
              This Cookie Notice explains how FotoSoft uses cookies and similar
              technologies in connection with the FotoSoft.com website and any
              other website that we own or control and which posts or links to
              this Cookie Notice (collectively, the “Sites”).
            </p>
            <h2 className="font-semibold">What are cookies?</h2>
            <p>
              Cookies are small data files that are placed on your computer or
              mobile device when you visit a website. Cookies serve different
              purposes, like helping us understand how a site is being used,
              letting you navigate between pages efficiently, remembering your
              preferences, and generally improving your browsing experience.
            </p>
            <p>
              Our Sites may use both session cookies (which expire once you
              close your web browser) and persistent cookies (which stay on your
              computer or mobile device until you delete them).
            </p>
            <p>
              We use two broad categories of cookies: (1) first party cookies,
              served directly by us to your computer or mobile device, which we
              use to recognize your computer or mobile device when it revisits
              our Sites; and (2) third-party cookies, which are served by
              service providers or business partners on our Sites, and can be
              used by these parties to recognize your computer or mobile device
              when it visits other websites. Third-party cookies can be used for
              a variety of purposes, including site analytics, advertising, and
              social media features.
            </p>
            <h2 className="font-semibold">What types of cookies?</h2>
            <p>
              On the Sites, we use cookies and other tracking technologies in
              the following categories described in the table below.
            </p>
            <h3 className="font-semibold">Advertising and Targeting Cookies</h3>
            <p>
              These cookies are used by advertising companies to collect
              information about how you use our Sites and other websites over
              time. These companies use this information to show you ads they
              believe will be relevant to you within our services and elsewhere,
              and to measure how the ads perform.
            </p>
            <h3 className="font-semibold">Analytics and Performance Cookies</h3>
            <p>
              These cookies help us understand how our services is performing
              and being used. These cookies may work with web beacons included
              in emails we send to track which emails are opened and which links
              are clicked by recipients.
            </p>
            <h3 className="font-semibold">Essential Cookies</h3>
            <p>
              These cookies are necessary to allow the technical operation of
              our services (e.g., they enable you to move around on a website
              and to use its features).
            </p>
            <h3 className="font-semibold">Functionality Cookies</h3>
            <p>Enhance the performance and functionality of our services.</p>
            <h3 className="font-semibold">Social Media Cookies</h3>
            <p>
              These cookies may allow you to log into the Sites or App through
              your social media account or share content in our sites that you
              find interesting through third-party social media providers. These
              cookies may also be used for advertising purposes.
            </p>
            <h3 className="font-semibold">Browser Web Storage</h3>
            <p>
              We may use browser web storage (including via HTML5), also known
              as locally stored objects (“LSOs”), for similar purposes as
              cookies. Browser web storage enables the storage of a larger
              amount of data than cookies. Your web browser may provide
              functionality to clear your browser web storage.
            </p>
            <h2 className="font-semibold">Your choices</h2>
            <p>
              Your options for controlling what information cookies and similar
              technologies collect about you include:
            </p>
            <h3 className="font-semibold">Blocking cookies in your browser</h3>
            <p>
              Most browsers let you remove or reject cookies. To do this, follow
              the instructions in your browser settings. Many browsers accept
              cookies by default until you change your settings. Please note
              that if you set your browser to disable cookies, the Sites may not
              work properly. For more information about cookies, including how
              to see what cookies have been set on your device and how to manage
              and delete them, visit allaboutcookies.org. If you do not accept
              our cookies, you may experience some inconvenience in your use of
              our Sites. For example, we may not be able to recognize your
              computer or mobile device and you may need to log in every time
              you visit our Sites.
            </p>
            <h3 className="font-semibold">Third-party opt-out option</h3>
            <p>
              You can opt-out of interest-based advertising through some of the
              third parties listed in the chart above by using the corresponding
              third-party opt-out tool provided in the chart.
            </p>
            <h3 className="font-semibold">Blocking advertising ID use in your mobile settings</h3>
            <p>
              Your mobile device settings may provide functionality to limit use
              of the advertising ID associated with your mobile device for
              interest-based advertising purposes.
            </p>
            <p>
              For more information about cookies, including how to see what
              cookies have been set on your computer or mobile device and how to
              manage and delete them, visit allaboutcookies.org. If you do not
              accept our cookies, you may experience some inconvenience in your
              use of our Sites. For example, we may not be able to recognize
              your computer or mobile device and you may need to log in every
              time you visit our Sites.
            </p>
            <p>
              If you choose to opt-out of targeted advertisements, you will
              still see advertisements online, but they may not be relevant to
              you. Even if you do choose to opt out, not all companies that
              serve online behavioral advertising are included in this list, and
              so you may still receive some cookies and tailored advertisements
              from companies that are not listed.
            </p>
          </article>
          <RightNav />
        </div>
      <Footer />
    </>
  );
}
