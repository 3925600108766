import Footer from "../components/Footer";
import Header from "../components/Header";
import RightNav from "../components/RightNav";

export default function Refund() {
  return (
    <>
      <Header />
      <div className="max-w-[1124px] px-4 pt-[144px] pb-16 mx-auto flex items-start lg:flex-col lg:pt-14 lg:pb-16">
        <article>
          <h1 className="font-bold">Refund Policy</h1>
          <p>
            FotoSoft values every customer and works hard to provide customers
            with an enjoyable experience using FotoSoft products and services.
            For any reasonable order dispute, FotoSoft welcomes customers to
            submit a complaint by sending email to{" "}
            <a
              href="mailto:support@fotosoft.net"
              className="text-blue-light hover:underline"
            >
              support@fotosoft.net
            </a>
            .
          </p>
          <p>
            FotoSoft values every customer and works hard to provide customers
            with an enjoyable experience using FotoSoft products and services.
            Most FotoSoft software offers a free trial version, so customers can
            'test-drive' before deciding to purchase. These trial versions have
            no functional limitation, only a watermark appearing on the finished
            media or a use limit. All these help customers make an informed
            purchase decision and avoid buying the wrong product for their
            needs.
          </p>
          <h2 className="font-semibold">Circumstances of No Refund</h2>
          <p>
            FotoSoft generally does not refund or exchange products in the
            following situations:
          </p>
          <h2 className="font-semibold">Non-technical Circumstances</h2>
          <p>
            1) Failure to read the product description before purchasing and
            thus resulting in dissatisfaction with the product's functioned
            and/or results. It is highly recommended that every customer read
            the product description and notice on checkout page before making
            their final purchase decision.
          </p>
          <p>
            FotoSoft does not refund software if products fail to meet
            customer's needs due to a lack of understanding by the customer, of
            the products functions and capabilities. However, FotoSoft can
            exchange the purchased product for the correct product outright,
            providing the price difference between products does not exceed USD
            $20, within the guarantee period. If the purchased product is
            exchanged for a correct product of lower price, FotoSoft will not
            refund the price difference.
          </p>
          <p>
            2) A customer refund request on complaint of credit card fraud/other
            unauthorized payment. FotoSoft recommends the customer contacts the
            card issuer if there is any suspicion of fraud or unauthorized
            payment. As FotoSoft cooperates with an independent payment
            platform, it is impossible to monitor authorization during payment.
            Once an order is processed and fulfilled, it cannot be cancelled.
            However, FotoSoft will exchange the purchased product for one the
            customer would like.
          </p>
          <p>
            3) A refund request claiming the failure to receive licensed account
            within two hours of the order having been successful. Ordinarily,
            once an order has been validated, FotoSoft 's system will
            automatically send an account activation e-mail within 1 hour.
            However, sometimes the arrival of this e-mail can be delayed, due to
            delays caused by internet or system glitches, email spam settings,
            email typo, etc. In this case, customers should contact FotoSoft
            support to retrieve it —all queries will be responded to within 24
            hrs.
          </p>
          <p>
            4) Purchase of a wrong product, which has already been followed by
            the purchase the correct product from another company.
          </p>
          <p>
            5) FotoSoft Product price differences between different regions or
            price differences between FotoSoft and other companies.
          </p>
          <p>
            6) A refund request for part of a bundle. FotoSoft cooperates with a
            third party payment platform which does not support any partial
            refund within an order; whereas, FotoSoft may refund the whole
            bundle after the customer has purchased the correct product
            separately within the purchased bundle's guarantee period.
          </p>
          <p>7) A customer has a 'change of mind' after purchase.</p>
          <h2 className="font-semibold">Technical Circumstances</h2>
          <p>
            1) A refund request due to technical trouble, with the customer
            refusing to cooperate with the FotoSoft support team in attempts at
            troubleshooting by declining to provide detailed descriptions and
            information regarding the problem, or refusing to try to apply the
            solutions provided by the FotoSoft support team.
          </p>
          <p>
            2) A refund request for technical problems after the software has
            been updated, if the order exceeds the days guarantee period.
          </p>
          <h2 className="font-semibold">Accepted Circumstances</h2>
          <p>
            FotoSoft offers refunds for the following circumstances within the
            guidelines of its Money Back Guarantee.
          </p>
          <h2 className="font-semibold">Non-technical Circumstances</h2>
          <p>
            1) A swap of a program would require you to purchase the proper one
            that you did not in the first place, and then the original program
            would be refunded back to you.
          </p>
          <p>
            2) Purchase of the same product twice or purchase of two products
            with similar functions. In this case, FotoSoft will refund one of
            the products for you, or swap one program for another FotoSoft
            product.
          </p>
          <p>
            3) The customer does not get account activation email within 24
            hours of purchase, has failed to retrieve account from the FotoSoft
            Support Center. In this case, FotoSoft will refund the customer's
            order if they have no need of the product in future.
          </p>
          <p>
            4) If following a technical fault the user purchased a different
            software product from another company to finish their task. Under
            these circumstances, we can arrange to exchange the product or
            provide a full refund.
          </p>
          <h2 className="font-semibold">Technical Problems</h2>
          <p>
            Software purchased has terminal technical problems, and no solution
            has been provided within 30 days. In this case, FotoSoft will refund
            the purchase price if the customer does not want to wait for a
            future upgrade.
          </p>
          <p>
            Once a refund is issued, the corresponding licensed account will be
            deactivated.
          </p>
          <p>
            FotoSoft will issue refunds either to the credit card, bank or
            PayPal account used in the purchase. Depending on the user's bank
            situation, refunds may take 48 to 72 hours to be credited.
          </p>
        </article>
        <RightNav />
      </div>
      <Footer />
    </>
  );
}
