import Footer from "../components/Footer";
import Header from "../components/Header";
import RightNav from "../components/RightNav";

export default function Contact() {
  return (
    <>
      <Header />
      <main className="h-[calc(100vh-137px)]">
        <div className="max-w-[1124px]  px-4 text-black-dark pt-[144px] pb-16 mx-auto flex items-start lg:flex-col lg:pt-14 lg:pb-16">
          <article>
            <h1 className="font-bold">Contact Us</h1>
            <p>
              If you have any questions, comments, or suggestions, please send
              them to{" "}
              <a
                href="mailto:support@fotosoft.net"
                className="text-blue-light hover:underline"
              >
                support@fotosoft.net
              </a>
            </p>
            <p>
              A 24/7 team from FotoSoft will get back to you as soon as
              possible.
            </p>
          </article>
          <RightNav />
        </div>
      </main>
      <Footer />
    </>
  );
}
