import Footer from "../components/Footer";
import Header from "../components/Header";

const list = [
  { text: "30+ Art Mediums" },
  { text: "10+ Art Styles" },
  { text: "20+ Moods" },
  { text: "10+ Artist Styles" },
  { text: "3D Renders" },
  { text: "Photorealistic" },
  { text: "Volumetric Lighting" },
  { text: "Digital Art" },
  { text: "4k Resolution" },
  { text: "Unlimited Save Generations" },
];

const BlueSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8445 7.68891C17.2602 7.30582 17.9056 7.3245 18.2985 7.73098C18.6894 8.13544 18.6882 8.77725 18.296 9.18035L11.2505 16.4202C10.9436 16.7356 10.4352 16.7297 10.1356 16.4073L6.68834 12.6975C6.30234 12.2821 6.30866 11.6373 6.70273 11.2295C7.09782 10.8207 7.74404 10.7935 8.17217 11.1675L10.6863 13.3642L16.8445 7.68891Z"
        fill="#266FE8"
      />
    </svg>
  );
};
const GreenSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8445 7.68891C17.2602 7.30582 17.9056 7.3245 18.2985 7.73098C18.6894 8.13544 18.6882 8.77725 18.296 9.18035L11.2505 16.4202C10.9436 16.7356 10.4352 16.7297 10.1356 16.4073L6.68834 12.6975C6.30234 12.2821 6.30866 11.6373 6.70273 11.2295C7.09782 10.8207 7.74404 10.7935 8.17217 11.1675L10.6863 13.3642L16.8445 7.68891Z"
        fill="white"
      />
    </svg>
  );
};

export default function Pricing() {
  return (
    <>
      <Header navVisibility={false} />
      <main className="pt-16 lg:pt-14 leading-7 min-h-[calc(100vh-137px)] lg:min-h-full isolate text-white bg-[#10101C] relative z-[2]">
        <div className="mt-16 max-w-[1180px] mx-auto text-center pb-[162px] lg:px-4 lg:pb-10">
          <h1 className="text-[56px] leading-[72px] lg:text-2xl">
            Simple transparent pricing.
          </h1>
          <div className="grid grid-cols-3 gap-x-5 mt-12 lg:grid-cols-1 lg:gap-y-4 lg:mt-5">
            {[
              {
                title: "Starter",
                titleClass: "bg-[#FFFFFF]  bg-opacity-[6%]",
                svg: <BlueSvg />,
                bgClass: "bg-[#18191B]",
                price: "$ 49",
                desc: (
                  <>
                    Save 50% when you chose annual. Billed as $299 a year.
                    Modify or cancel your plan anytime.
                  </>
                ),
                buttonClass: "bg-blue-light text-white",
                topText: "2000 AI image credits per month",
              },
              {
                title: "Professional",
                titleClass: "bg-[#0F59D3]",
                svg: <GreenSvg />,
                bgClass: "bg-[#266FE8]",
                price: "$ 179",
                desc: (
                  <>
                    Save 50% when you chose annual. Billed as $1079 a year.
                    Modify or cancel your plan anytime.
                  </>
                ),
                buttonClass: "bg-white text-blue-light hover:text-white",
                topText: "10,000 AI image credits per month",
              },
              {
                title: "Scale",
                titleClass: "bg-[#FFFFFF]  bg-opacity-[6%]",
                svg: <BlueSvg />,
                bgClass: "bg-[#18191B]",
                price: "$ 399",
                desc: (
                  <>
                    Save 50% when you chose annual. Billed as $2379 a year.
                    Additional credits billed as $100 per 10k credits.
                  </>
                ),
                buttonClass: "bg-blue-light text-white",
                topText: "30,000 AI image credits per month",
              },
            ].map((item, index) => {
              return (
                <div
                  key={index}
                  className={`shadow-card ${item.bgClass} rounded-[20px] pb-14 overflow-hidden text-center`}
                >
                  <h3
                    className={`${item.titleClass} py-4 text-lg font-bold lg:text-base lg:py-2`}
                  >
                    {item.title}
                  </h3>
                  <div className="px-10 lg:px-4">
                    <p className="text-[28px] leading-10 font-extrabold pt-5 lg:text-base lg:pt-2">
                      1 month
                    </p>
                    <p className="mt-5 text-[48px] leading-[64px] font-extrabold lg:text-xl">
                      {item.price}
                    </p>
                    <p className="text-sm mt-2 text-center max-w-[300px] mx-auto w-full">
                      {item.desc}
                    </p>
                    <ul className="mt-5 lg:mt-2">
                      <li className="flex items-center gap-x-1 lg:gap-x-[2px]">
                        {item.svg}
                        {item.topText}
                      </li>
                      {list.map((v, i) => {
                        return (
                          <li
                            className="flex items-center gap-x-1 lg:gap-x-[2px]"
                            key={i}
                          >
                            {item.svg}
                            {v.text}
                          </li>
                        );
                      })}
                    </ul>
                    <a
                      href="/sign-up"
                      className={`py-4 mt-5 text-center flex max-w-[300px] mx-auto w-full justify-center text-2xl font-extrabold rounded-full lg:text-xl lg:mt-2
                   hover:bg-[#0F59D3] hover:shadow-card2 ${item.buttonClass}`}
                    >
                      Start Using
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </main>
      <Footer website={false} bgHeight={false} />
    </>
  );
}
