import React, { useState, useEffect } from "react";
const Rightnav = () => {
  const [navValue, setNavValue] = useState("");
  useEffect(() => {
    setNavValue(window.location.pathname);
  }, []);

  const rightList = [
    { link: "/privacy-policy", text: "Privacy Policy" },
    { link: "/eula", text: "Eula" },
    { link: "/terms-of-service", text: "Terms of Service" },
    { link: "/cookie-notice", text: "Cookie Notice" },
    { link: "/refund-policy", text: "Refund Policy" },
    { link: "/recurring-policy", text: "Recurring Policy" },
    { link: "/contact-us", text: "Contact Us" },
  ];
  return (
    <div className="lg:w-full lg:px-4">
      <div className="ml-8 w-[160px] text-sm leading-[22px] font-semibold shadow-card3 bg-white border rounded-lg sticky top-20 lg:w-full  lg:mt-8 lg:ml-0">
        <div className="h-2 rounded-t-[3px] w-full bg-blue-light"></div>
        <ul className="pt-1 mb-3 space-y-1">
          {rightList.map((item, index) => {
            return (
              <li
                key={index}
                className={`py-1 pl-4 ${
                  navValue === item.link
                    ? " bg-opacity-[0.1] bg-blue-light text-blue-light"
                    : "text-black"
                }`}
              >
                <a
                  href={item.link}
                  rel="nofollow"
                  className="hover:text-blue-light"
                >
                  {item.text}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Rightnav;
