import Footer from "../components/Footer";
import Header from "../components/Header";

const OKSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="9" fill="#26BC5E" fillOpacity="0.16" />
      <path
        d="M12.1451 5.88725C12.4643 5.64767 12.911 5.67932 13.1932 5.96151C13.5071 6.27534 13.5062 6.78443 13.1913 7.09717L7.89886 12.353C7.67912 12.5712 7.32322 12.5671 7.10857 12.3439L4.55758 9.69071C4.23052 9.35056 4.23582 8.81124 4.56948 8.47757C4.84198 8.20508 5.26171 8.14532 5.59943 8.33093L7.49908 9.375L12.1451 5.88725Z"
        fill="#26BC5E"
      />
    </svg>
  );
};

export default function Index() {
  return (
    <>
      <Header />
      <main className="pt-16 lg:pt-14 leading-7 isolate text-white bg-[#10101C] relative z-[2]">
        <section className="relative overflow-hidden min-h-[860px]  lg:min-h-full xl:pb-20 md:pb-0">
          <div className="relative z-[2]">
            <div className="max-w-[900px] relative text-center px-4 mx-auto mt-[128px] lg:px-6  lg:mt-12  sm:mt-6">
              <h1 className="text-[64px] leading-[80px] font-extrabold lg:text-2xl">
                Generate distinctive AI images in just one simple click.
              </h1>
              <p className="mt-6 max-w-[640px] w-full mx-auto text-lg lg:mt-3 lg:text-sm">
                FotoSoft AI lets your imagination go wild. Experience the ease
                of generating countless beautiful images with a single click.
              </p>
              <div className="pt-[54px]  lg:pt-8 lg:pb-12">
                <a
                  className="bg-blue-light text-white text-xl font-extrabold px-10 py-[14px] hover:bg-[#0F59D3] hover:shadow-card2"
                  href="/sign-up"
                >
                  Start Using
                </a>
              </div>
            </div>
          </div>
          <figure className="absolute top-0 left-0 right-0 bottom-0">
            <img
              src="/images/index/bg.jpg"
              className="object-cover w-full h-full"
              alt="background img"
            />
          </figure>
        </section>

        <section
          className={`max-w-[1200px] mx-auto bg-white  px-12 py-16 -mt-[188px] relative z-[4] lg:mt-0 md:px-5 md:py-6 `}
        >
          <div className="flex gap-x-5">
            <figure>
              <img
                src="/images/index/fish.jpg"
                className="rounded-l-full w-full h-full"
                alt="banner img"
              />
            </figure>
            <figure>
              <img
                src="/images/index/city.jpg"
                className=" w-full h-full"
                alt="banner img"
              />
            </figure>
            <figure>
              <img
                src="/images/index/umbrella.jpg"
                className="rounded-r-full w-full h-full"
                alt="banner img"
              />
            </figure>
          </div>
          <h2 className="text-black-dark text-[64px] leading-[72px] font-extrabold text-center pt-12 lg:text-xl lg:pt-5">
            The easiest way to produce
            <br />
            AI-generated artwork
          </h2>
          <div className="flex gap-x-16 text-black-dark max-w-[592px] mx-auto pt-12 lg:pt-5 lg:flex-col">
            <div className="text-center space-y-3 lg:space-y-2">
              <p className="text-blue-light text-[56px] leading-[64px] font-extrabold lg:text-xl">
                110+
              </p>
              <p className=" font-medium lg:text-sm">Artistic Modes</p>
            </div>
            <div className="text-center space-y-3 lg:space-y-2">
              <p className="text-blue-light text-[56px] leading-[64px] font-extrabold lg:text-xl">
                Limitless
              </p>
              <p className=" font-medium lg:text-sm">Distinctive Creations</p>
            </div>
            <div className="text-center space-y-3 lg:space-y-2">
              <p className="text-blue-light text-[56px] leading-[64px] font-extrabold lg:text-xl">
                15+
              </p>
              <p className="font-medium lg:text-sm">TemplSates</p>
            </div>
          </div>
        </section>

        <section className="max-w-[1200px] mx-auto w-full mt-40 flex items-center gap-x-24 lg:flex-col xl:px-4 lg:mt-10">
          <figure className="shrink-0">
            <img src="/images/index/generate.png" alt="Static Banner" />
          </figure>
          <div>
            <h2 className="text-[64px] leading-[72px] font-extrabold lg:text-xl">
              Generate any image with just one click.
            </h2>
            <p className="pt-3 lg:pt-2 lg:text-sm">
              Stop spending money on royalty-free images and opt to rapidly
              generate the ideal one instead.
            </p>
            <ul className="space-y-2 pt-5 pb-8 lg:pt-3 lg:pb-5">
              {[
                { text: "Single-Click Image Creation" },
                { text: "Completely Distinctive Images" },
                { text: "Completely Fresh and New Images" },
              ].map((item, index) => {
                return (
                  <li className="flex items-center space-x-2" key={index}>
                    <OKSvg />
                    <span>{item.text}</span>
                  </li>
                );
              })}
            </ul>
            <a
              href="/sign-up"
              className="bg-blue-light px-8 py-3 rounded shadow-card2 hover:bg-[#0F59D3] "
            >
              Create a free account
            </a>
          </div>
        </section>

        <section className="max-w-[1200px] mx-auto w-full mt-40 lg:mt-10 lg:px-4">
          <div>
            <h2 className="text-[64px] leading-[72px] font-extrabold text-center max-w-[900px] mx-auto lg:text-xl lg:text-left">
              Seamless Designing Experience
            </h2>
            <ul className="grid grid-cols-2 gap-10 mt-12 lg:grid-cols-1 lg:gap-5 lg:mt-5">
              {[
                {
                  title: "Computer-Generated Artwork",
                  img: "/images/index/artwork.png",
                  desc: (
                    <>
                      Generate unique digital art in real time with a single
                      click. Produce an array of various art styles, including
                      abstract, surrealistic, and figurative paintings,
                      portraits, and landscapes.
                    </>
                  ),
                },
                {
                  title: "Content for Social Media",
                  img: "/images/index/media.png",
                  desc: (
                    <>
                      Transform your social media strategy with FotoSoft AI!
                      Easily generate unique, attractive images with a single
                      click. Share your product or service with a visual impact
                      on social media.
                    </>
                  ),
                },
                {
                  title: "Advertising",
                  img: "/images/index/advertising.png",
                  desc: (
                    <>
                      Unleash your creativity with a single click! Generate
                      Stunning, Attention-Grabbing Images on Your Desktop. This
                      is ideal for businesses in need of visual marketing
                      content or Ad images.
                    </>
                  ),
                },
                {
                  title: "On-Demand Printing",
                  img: "/images/index/printing.png",
                  desc: (
                    <>
                      Take your print-on-demand to the next level! Generate
                      unlimited artwork to add visual appeal to your products,
                      saving time and money in the process.
                    </>
                  ),
                },
                {
                  title: "Visual Design",
                  img: "/images/index/visual.png",
                  desc: (
                    <>
                      Unleash your design potential with ease! Create beautiful
                      imagery without expertise in drawing, painting, or
                      Photoshop.
                    </>
                  ),
                },
                {
                  title: "Photography",
                  img: "/images/index/photography.png",
                  desc: (
                    <>
                      Transform your photography with a single click! Generate
                      unique artwork to spark your creativity and inspiration.
                    </>
                  ),
                },
              ].map((item, index) => {
                return (
                  <li
                    className="bg-black-light shadow-card3 p-8 lg:p-3"
                    key={index}
                  >
                    <figure>
                      <img src={item.img} alt={item.title} />
                    </figure>
                    <h3 className="pt-3 text-2xl font-extrabold lg:text-base lg:font-bold lg:pt-2">
                      {item.title}
                    </h3>
                    <p className="pt-3 lg:pt-2 lg:text-sm ">{item.desc}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="max-w-[1200px] mx-auto mt-40 lg:mt-10 ">
          <div className="bg-white py-12 px-14 flex items-center gap-x-[72px] lg:flex-col">
            <div className="text-black-dark">
              <h2 className="text-[64px] leading-[72px] font-extrabold w-full max-w-[488px] lg:text-xl lg:text-center">
                Unleash Your Creative Genius
              </h2>
              <p className="mt-3 lg:text-sm lg:mt-2 ">
                Our innovative approach opens up a world of endless
                possibilities for artists. We empower artists to unleash their
                full potential and bring their masterpieces to life with ease.
              </p>
              <div className=" pt-8 lg:pt-3 pb-5">
                <a
                  className="bg-blue-light text-white text-xl font-extrabold px-8 py-3 hover:bg-[#0F59D3] hover:shadow-card2"
                  href="/sign-up"
                >
                  Start free trial
                </a>
              </div>
            </div>
            <figure className=" shrink-0">
              <img src="/images/index/creative-genius.jpg" alt="Unleash Your Creative Genius" />
            </figure>
          </div>
        </section>

        <section className="max-w-[1200px] mx-auto w-full mt-40 lg:mt-10 lg:px-4 ">
          <h2 className="text-[64px] leading-[72px] font-extrabold max-w-[900px] w-full mx-auto text-center lg:text-xl">
            The FotoSoft AI always comes up with completely new images
          </h2>
          <ul className="grid grid-cols-2 gap-x-10 mt-12 lg:mt-3 lg:grid-cols-1 lg:gap-y-3">
            {[
              {
                name: "Sarah",
                jobTitle: "Creative Director",
                desc: (
                  <>
                    FotoSoft AI has exceeded my expectations as a software
                    engineer looking to integrate AI into my creative projects.
                    Specifically, I've been using it for my NFT project, and the
                    results have been amazing. Highly recommend.
                  </>
                ),
              },
              {
                name: "Michael",
                jobTitle: "Art Director",
                desc: (
                  <>
                    I've been a fan of FotoSoft for its ability to enhance my
                    creative work. The interface is user-friendly, and the
                    results are stunning. I would highly recommend this to
                    anyone in the AI and creative industries. Incredibly
                    impressive outcomes and are very simple to use. Amazing
                    results, and very simple to use. A must-have in any creative
                    toolkit.
                  </>
                ),
              },
            ].map((item, index) => {
              return (
                <li
                  className="bg-black-light p-12 shadow-card3 lg:p-5"
                  key={index}
                >
                  <figure>
                    <img src="/images/index/star.png" alt={item.name} />
                  </figure>
                  <p className="mt-5 font-extrabold text-2xl lg:text-sm">
                    {item.desc}
                  </p>
                  <p className="mt-5 lg:text-sm">
                    <span className=" font-bold">{item.name}</span>,
                    {item.jobTitle}
                  </p>
                </li>
              );
            })}
          </ul>
        </section>

        <section className="max-w-[1200px] mx-auto w-full mt-40  xl:px-4 lg:mt-8">
          <div className="flex items-center gap-x-8 lg:flex-col lg:gap-y-2">
            <h2 className="text-[64px] leading-[72px] w-[680px] font-extrabold shrink-0 lg:w-full lg:text-xl">
              Designed exclusively for the artists, the designers, and the
              creators.
            </h2>
            <p className="text-base">
              We're combining creativity and technology to make your visions a
              reality. Our mission is to facilitate the production of the next
              groundbreaking work of art, design, or creation. Utilizing them
              allows them to make more realistic graphics in less time.
            </p>
          </div>

          <div className="bg-black-light py-12 px-14 mt-12 flex items-center gap-x-[72px] lg:flex-col lg:mt-5 lg:p-3">
            <div>
              <h3 className="text-4xl leading-[48px] font-extrabold lg:text-base lg:font-bold">
                Designed for creators by creators.
              </h3>
              <p className="mt-3 lg:text-sm lg:mt-2">
                Made for designers and creative professionals, this tool
                streamlines the process of creating unique digital art, serving
                as your personal artistry assistant.
              </p>
            </div>
            <figure className=" shrink-0 lg:mt-3">
              <img
                src="/images/index/creators.png"
                alt=" Designed for creators by creators."
              />
            </figure>
          </div>
        </section>

        <section className="max-w-[1200px] mx-auto w-full pt-40 pb-[136px] lg:pb-5  lg:pt-8">
          <div className="bg-white p-12 flex items-center gap-x-[72px] lg:flex-col lg:p-5">
            <figure className=" shrink-0">
              <img
                src="/images/index/digital-art.jpg"
                alt="Push the boundaries of digital art with FotoSoft AI"
              />
            </figure>
            <div className="text-black-dark lg:mt-3">
              <h2 className="text-[64px] leading-[72px] font-extrabold lg:text-xl">
                Push the boundaries of digital art with FotoSoft AI
              </h2>
              <p className="mt-5 lg:mt-2 lg:text-sm">
                FotoSoft AI simplifies image creation with its cutting-edge
                neural networks and AI algorithms. With just one click, you can
                generate new and unique images.
              </p>
              <p className="mt-5 lg:mt-2 lg:text-sm">
                Check out the input and see how FotoSoft AI brought it to life
                in the form of an entirely new image. With a simple input, this
                tool will generate an entirely original image that has never
                existed before. Open up your imagination and make your wildest
                dreams a reality.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
