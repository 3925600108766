import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import cocospy from "../assets/cocospy.svg";
import minspy from "../assets/minspy.svg";
import spyic from "../assets/spyic.svg";
import neatspy from "../assets/neatspy.svg";
import spyier from "../assets/spyier.svg";
import spyzie from "../assets/spyzie.svg";
import spyine from "../assets/spyine.svg";
import clickfree from "../assets/clickfree.svg";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Item = ({ label, value }: { label: string; value: string }) => {
  return (
    <li className="flex justify-between items-center mt-3 last:mt-6">
      <p className="text-base">{label}</p>
      <p className="text-base font-semibold">{value}</p>
    </li>
  );
};

declare var Paddle: any;
declare var location: any;
declare var LemonSqueezy: any;
declare global {
  interface Window {
    createLemonSqueezy:any
  }
}



// 获取url参数
let payUrl = ''
const Payment = () => {
  const [product, setProduct] = useState<any>({
    name: "test",
    month: 3,
    price: 100,
  });
  // const [product, setProduct] = useState(null);
  const { id: cart } = useParams();
  const [origin, setOrigin] = useState("fotosoft");

  const items = useMemo(
    () =>
      product
        ? [
            {
              label: "Plan",
              value: product.name,
            },
            {
              label: "Subscription period",
              value: `${product.month} month`,
            },
            {
              label: "1 month price",
              value: `$${
                Math.floor((product.price / product.month) * 100) / 100
              }`,
            },
          ]
        : [],
    [product]
  );

  const getCardInfo = async () => {
    // 获取
    try {
      const result = await axios.post("/api/cartCheckout/getCartInfo", {
        data: {
          cart,
        },
      });
      setProduct(result.data.data.products[0]);
      payUrl = result.data.data.products[0].checkout_url
      const { pay_method, website } = result.data.data;
      setOrigin(website);
      const method = pay_method.filter(
        (data: any) => data.type === "lemon" //"paddle"
      )[0];
      if (!method) {
        return;
      }
      if(LemonSqueezy){
          LemonSqueezy.Setup({
            eventHandler: (data: any) => {
                if(data.event === "Checkout.Success"){
                  console.log(data.event); // Data specifics on the event
                  if (website) {
                    let domainLtd = "com";
                    if (website === "spyzie") {
                      domainLtd = "io";
                    }
                    if (website === "fonemonitor") {
                      domainLtd = "co";
                    }
                    if (website === "teensafe") {
                      domainLtd = "net";
                    }
                    location.href =
                      `https://${website}.${domainLtd}/payment.html?id=` +
                      cart;
                  } else {
                    location.href = "/";
                  }
                } else if (data.event === "PaymentMethodUpdate.Closed") {
                  console.log(data.eventData); // Data specifics on the event
                }
            }
        })
        LemonSqueezy.Url.Open(payUrl);
      }

      // 渲染paddle
      // if (method.is_test) {
      //   Paddle.Environment.set("sandbox");
      // }
      // Paddle.Setup({
      //   vendor: method.client_id,
      //   eventCallback: (data: any) => {
      //     if (data.event === "Checkout.Complete") {
      //       console.log(data.eventData); // Data specifics on the event
      //       if (website) {
      //         let domainLtd = "com";
      //         if (website === "spyzie") {
      //           domainLtd = "io";
      //         }
      //         if (website === "fonemonitor") {
      //           domainLtd = "co";
      //         }
      //         if (website === "teensafe") {
      //           domainLtd = "net";
      //         }

      //         location.href =
      //           `https://${website}.${domainLtd}/payment.html?id=` +
      //           cart;
      //       } else {
      //         location.href = "/";
      //       }
      //     } else if (data.event === "Checkout.Close") {
      //       console.log(data.eventData); // Data specifics on the event
      //     }
      //   },
      // });
      // // 开启paddle
      // Paddle.Checkout.open({
      //   method: "inline",
      //   email: result.data.data.email,
      //   country: result.data.data.country,
      //   product: result.data.data.products[0].paddle_id, // Replace with your Product or Plan ID
      //   // product: method.is_test ? 1 : result.data.data.products[0].id, // Replace with your Product or Plan ID
      //   allowQuantity: false,
      //   disableLogout: true,
      //   passthrough: cart,
      //   frameTarget: "checkout-container", // The className of your checkout <div>
      //   // frameInitialHeight: 416,
      //   frameStyle:
      //     "width:100%; min-width:312px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
      // });
    } catch (e: any) {
      alert(e.message);
    }
  };

  const ref = useRef(false);

  useEffect(() => {
    // 根据
    if (ref.current === false) {
      window.createLemonSqueezy()
      getCardInfo();
      ref.current = true;
    }
  }, []);

  const image = useMemo(() => {
    switch (origin) {
      case "cocospy":
        return cocospy;
      case "spyic":
        return spyic;
      case "minspy":
        return minspy;
      case "spyier":
        return spyier;
      case "spyzie":
        return spyzie;
      case "spyine":
        return spyine;
      case "neatspy":
        return neatspy;
      case "safespy":
        return require("../assets/safespy.png");
      case "clickfree":
        return clickfree;
      default:
        return require("../assets/fotosoft.png");
    }
  }, [origin]);

  return (
    <div>
      <Header website={origin} navVisibility={false} />
      <div className="w-[1232px] mx-auto flex py-[104px] justify-between gap-16 lg:gap-8 lg:w-full lg:flex-col px-4 lg:py-20 lg:box-border">
        {/* {product ? ( */}
        <div className="w-full lg:mb-5 lg:box-border">
          <div className="flex">
            <img className="w-[100px] mr-6" src={image} alt="" />
            <div className="info">
              <h2 className="text-base">Total Price</h2>
              <p className="text-xl font-extrabold text-[#FF5A00]">
                ${product.price}
              </p>
            </div>
          </div>
          <ul className="mt-12 sm:mt-5 border-b border-[#D6DAE0] pb-8 mb-8">
            {items.map((item) => (
              <Item {...item} key={item.label} />
            ))}
            <Item label="Total Price" value={`$${product.price}`}></Item>
          </ul>
          <div className="w-[208px] ml-auto bg-[#266FE8] hover:opacity-80 transition text-white rounded-lg h-14 hover:cursor-pointer flex justify-center items-center text-2xl font-bold" onClick={()=>{LemonSqueezy.Url.Open(payUrl)}}>Pay Now</div>
          {/* <p className="coupon">I have a coupon code</p> */}
          <div className="p-3 mt-12 border border-[#D6DAE0] rounded">
            <p className="text-xs font-normal">By clicking “submit order” you agree to & as well as to Terms of Service & Recurring Policy. as well as to Refund Policy & Legal  Disclaimer.</p>
            <div className="mt-5 flex justify-between">
              <img className="h-8 lg:h-5" src={require("../assets/tip1@2x.png")} alt="" />
              <img className="h-8 lg:h-5" src={require("../assets/tip2@2x.png")} alt="" />
              <img className="h-8 lg:h-5" src={require("../assets/tip3@2x.png")} alt="" />
              <img className="h-8 lg:h-5" src={require("../assets/tip4@2x.png")} alt="" />
              <img className="h-8 lg:h-5" src={require("../assets/tip5@2x.png")} alt="" />
            </div>
          </div>
        </div>
        {/* ) : null} */}

        {/* <div className="flex-1 min-h-[586px] bg-white border border-[#E8EAF2] shadow-card rounded-xl p-6  checkout-container lg:w-full lg:min-h-full sm:p-0"></div> */}
      </div>
      <Footer website={false} />
    </div>
  );
};

export default Payment;
