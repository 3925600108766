import Footer from "../components/Footer";
import Header from "../components/Header";

const signList = [
  {
    icon: "/images/sign/google.svg",
    text: "Continue with Google",
    link: "/#",
  },
  {
    icon: "/images/sign/facebook.svg",
    text: "Continue with Facebook",
    link: "/#",
  },
  {
    icon: "/images/sign/email.svg",
    text: "Continue with Email",
    link: "/#",
  },
];
export default function SignUp() {
  return (
    <>
      <Header />
      <main className="pt-16 min-h-[calc(100vh-137px)] lg:min-h-full lg:pt-14 relative z-[2]">
        <section className="py-20 lg:px-4 ">
          <div className="max-w-[520px] mx-auto w-full shadow-card text-center rounded-xl px-8 py-10">
            <h2 className="text-2xl font-extrabold">Sign up</h2>
            <p className="text-sm mt-2">Let's create your free account.</p>
            <ul className="mt-8 space-y-6">
              {signList.map((v, i) => {
                return (
                  <li className="border py-3" key={i}>
                    <a
                      className="flex items-center justify-center gap-x-2"
                      href={v.link}
                    >
                      <img src={v.icon} alt={v.text} />
                      <span className=" font-semibold">{v.text}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <p className="mt-5 text-sm">
              Already have an account?
              <a href="/sign-in" className="text-blue-light hover:underline">
                {" "}
                Sign in
              </a>
            </p>
            <p className="mt-5 text-xs">
              By creating an account, you agree to The{" "}
              <a
                href="/terms-of-service"
                className="text-blue-light hover:underline"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                className="text-blue-light hover:underline"
              >
                {" "}
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
